<template>
    <mentionable
        :keys="keys"
        :limit="20"
        :items="processedItems"
        filtering-disabled
        insert-space
        class="mentionable-wrapper"
        v-bind="proxyProps"
        v-on="$listeners"
        @search="handleSearch"
        @open="handleOpen"
        @close="handleClose"
        @apply="handleApply"
    >
        <template #no-result>
            <div class="mentionable-wrapper__info">
                {{ loading ? 'Загрузка' : 'Нет данных' }}
            </div>
        </template>

        <template #item="{ item }">
            <slot
                name="item"
                :item="item.originalData"
            />
        </template>

        <slot />
    </mentionable>
</template>

<script lang="ts">
import WrapperMixin from '@/mixins/wrapper';
import Vue, { PropType } from 'vue';
import type { MentionItem } from 'vue-mention/dist/Mentionable.vue';
import { debounce, isEqual, uniqBy, snakeCase } from 'lodash';

export default Vue.extend({
    name: 'UiMentionableWrapper',
    mixins: [WrapperMixin],
    props: {
        fetchItems: {
            type: Function as PropType<(query: string, cb: (items: any[]) => void) => void>,
            required: true
        },

        labelKey: {
            type: String,
            default: 'name'
        },

        // Для поиска упоминаний в тексте
        inputText: {
            type: String,
            default: ''
        },

        // Чтобы стриггерить фокус после выбора чела
        inputComponent: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            items: [] as any[],
            loading: false,
            mentions: [] as any[],
            keys: ['@', '+'],
            popoverVisible: false,
            searchString: ''
        };
    },

    computed: {
        processedItems (): (MentionItem & { originalData: any })[] {
            return this.items.map(i => ({
                label: i[this.labelKey],
                value: snakeCase(i[this.labelKey]),
                originalData: i
            }));
        },

        isLastSymbolKey (): boolean {
            return this.keys.includes(this.inputText[this.inputText.length - 1]);
        }
    },

    watch: {
        inputText: debounce(function (val, oldVal) {
            this.mentions = uniqBy(this.mentions.filter(i => {
                return this.keys.some(key => val.includes(key + snakeCase(i[this.labelKey])));
            }), 'id');

            const isDeleting = val.length < oldVal.length;

            if (
                isDeleting &&
                oldVal[oldVal.length - 1] !== ' ' &&
                (this.isLastSymbolKey && this.popoverVisible)
            ) {
                this.remoteMethod('');
            }
        }, 300)
    },

    created () {
        this.$watch(() => ({ ...this.mentions }), (val, oldVal) => {
            if (!isEqual(val, oldVal)) {
                this.$emit('change-mentions', this.mentions);
            }
        }, { deep: true });
    },

    methods: {
        remoteMethod (queryString: string) {
            this.loading = true;
            this.items = [];
            this.fetchItems(queryString, (items: any[]) => {
                this.items = items;
                this.loading = false;
            });
        },

        handleSearch: debounce(function (value: string) {
            this.searchString = value;
            this.remoteMethod(value);
        }, 300),

        handleOpen () {
            this.popoverVisible = true;
            if (this.isLastSymbolKey || this.searchString) {
                this.remoteMethod(this.isLastSymbolKey ? '' : this.searchString);
            }
        },

        handleApply (item) {
            this.searchString = '';
            this.mentions.push(item.originalData);

            if (this.inputComponent) {
                setTimeout(() => {
                    this.inputComponent.focus();
                }, 0);
            }
        },

        handleClose () {
            this.popoverVisible = false;
        }
    }
});
</script>

<style lang="scss">
.v-popper {
    &__popper {
        z-index: 1000;
    }

    &__inner {
        max-height: 200px;

        &:hover {
            &::-webkit-scrollbar-thumb {
                background: rgba(157, 170, 182, 0.3);
            }
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }
        &::-webkit-scrollbar-button {
            width: 2px;
            height: 2px;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
            border-radius: 4px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(157, 170, 182, 0.5);
        }
    }
}

.v-popper--theme-dropdown {
    .v-popper__inner {
        max-height: 200px;
        padding: 3px 0;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.mention-item {
    line-height: 24px;
    padding: 0 10px;
    font-size: 12px;
    color: #24292e;
    cursor: pointer;

    &:hover, &.mention-selected {
        background-color: #f0f3f8;
    }
}

.mentionable-wrapper {
    &__info {
        padding: 3px 10px;
    }
}
</style>
